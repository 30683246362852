

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddUserProfileBrand from "@/components/modals/forms/AddUserProfileBrand.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, {business_types} from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import JwtService from "@/core/services/JwtService";
import { hideModal } from "@/core/helpers/dom";
import { useRouter } from "vue-router";
import {active} from "@/core/data/genericData";
export default defineComponent({

  name: "brand-listing",
  props: {    
    id: { required: true },
    data: {
        type: Object
    },
  },
  components: {
    ExportCustomerModal,
    AddUserProfileBrand, 
  },
  
  setup(props) {
    const active_list_data = active;
    var ids = ref(props.id);
    const count = ref(0)
    const store = useStore();
    const checkedBranch = ref([]);  
    const formRef = ref<null | HTMLFormElement>(null);
    const editBrandModalRef = ref<null | HTMLElement>(null);
    const loadingData = ref<boolean>(true);  
    const loading = ref<boolean>(false);
    var formData = ref({})
    const router = useRouter();
    interface IBranches {
      brand_id,
      brand_name,
     // company_name,
      active: {
        label: string;
        color: string;
      }
    }

     const rules = ref({
      name: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",          
        },        
      ],
      
    });

    // const plant = ref([]);
    // const setPlantdata = async () => {
    //   const values = {
    //   "company_branch_id" : 0,
    //   "company_id":props.id,
    //   "page" : 1,
    //   "records_per_page" : 100
    //   };
    //   loading.value = true;
    //   try {
    //     await store
    //       .dispatch(Actions.CUST_GET_PLANT_LIST, values)
    //       .then(({ data }) => {
    //         plant.value = data.result_list;
    //         loading.value = false;
    //       })
    //       .catch(({ response }) => {
    //         console.log(response);
    //       });
    //   } catch (e) {
    //     console.log(e);
    //   }
    // };

     const setFormData = async (data) => {    
      data = data['brand_id'];
        try {

       var values = { 
            "brand_id" : data, 
            "company_id"  : props.id, 
            "page"  : 1, 
            "records_per_page" : 1 
            }
        
        await store.dispatch(Actions.CUST_GET_USER_BRAND_LIST, values).then(({ data }) => {    
          
          formData.value = {
             "id": data.brand_id,
            "name": data.brand_name,
            //"plant_select" : data.company_branch_id,
            "active": data.active
            };
          
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }

    var paginationData = ref({})
    
    paginationData.value = {      
          start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,
        mmaxPageg : 6,
    }

    const refreshData =() => {      
      paginationData.value['activePage']  = 1;     
      getKmpCompList(ids)
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;
      getKmpCompList(ids)
    }
    
    var tableData = ref<Array<IBranches>>([]);
    
    const getKmpCompList = async (data) => {
      
      loadingData.value = true;
      var com_id = 0
      if(data._value){
        com_id = data._value
      }else{
        com_id = data 
      }
      try {

        var values = { 
            "brand_id" : 0,
            "company_id"  : com_id, 
            "page"  : parseInt(paginationData.value['activePage']), 
            "records_per_page" : parseInt(paginationData.value['perPage']),
            "search_term" : ""}
        await store.dispatch(Actions.CUST_GET_USER_BRAND_LIST, values).then(({ data }) => {

        tableData.value = ([]);

        //pagination
          paginationData.value['total'] = data.total_records;        
          paginationData.value['start'] = data.records_from
          paginationData.value['end'] = data.records_upto
          paginationData.value['activePage'] = data.page;
          paginationData.value['totPage']  = data.total_pages
          paginationData.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
        
        var resultsM = ref<Array<IBranches>>([])
        var active_label = ""
        var active_color = ""

        for (let j = 0; j < data.result_list.length; j++) {

          if (data.result_list[j]['active']){
              active_label = "Yes";
              active_color = "success";                  
          }else{
              active_label = "No";
              active_color = "danger";
          }

          resultsM.value = Array({

            brand_id : data.result_list[j]['brand_id'],
            brand_name : data.result_list[j]['brand_name'],
           // company_name : data.result_list[j]['company_name'],
            active: {
              label: active_label,
              color: active_color
            }

          })

          tableData.value.splice(j, resultsM.value.length, ...resultsM.value);            
        }

        loadingData.value = false;

      })
      .catch(({ response }) => {
        
        tableData.value = [];
        loadingData.value = false; 
      });
        
      } catch (e) {
        console.log(e);
      }
    };

      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });

      // var tableData = ref<Array<WICompanies>>([]);
      const initCustomers = ref<Array<IBranches>>([]);                      

      onMounted( async () => {
        await getKmpCompList(props.id)
        await setFormData(props.data);
        //await setPlantdata()
        // setCurrentPageBreadcrumbs("KMP's",["company"]);
        initCustomers.value.splice(0, tableData.value.length, ...tableData.value);                
      });  

      const deleteFewCustomers = () => {
        checkedBranch.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedBranch.value.length = 0;
      };

      const deleteCustomer = (id) => {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].brand_id === id) {
            tableData.value.splice(i, 1);
          }
        }
      };

      const search = ref<string>("");
      const searchItems = () => {
        tableData.value.splice(0, tableData.value.length, ...initCustomers.value);        
        if (search.value !== "") {
          let results: Array<IBranches> = [];          
          for (let j = 0; j < tableData.value.length; j++) {
            if (searchingFunc(tableData.value[j], search.value)) {              
              results.push(tableData.value[j]);
            }
          }
          tableData.value.splice(0, tableData.value.length, ...results);
        }
      };

      const searchingFunc = (obj, value): boolean => {        
        for (let key in obj) {          
          if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {            
            if (obj[key].indexOf(value) != -1) {
              
              return true;
            }
          }
        }
        return false;
      };    
      
    const setBrandData = async (data) => {

      var user = JSON.parse(JwtService.getToken());
      
      const db_data = {
        "brand_id": data.id,
        "brand_name": data.name,
        "company_id": Number(props.id),
       // "company_branch_id": data.plant_select,
        "active": data.active,
        "user_id": user.user_id,        
        }

      await store.dispatch(Actions.CUST_ADD_USER_BRAND, db_data).then(({ data }) => {

        if (data){

            setTimeout(() => {
              loading.value = false;
              hideModal(editBrandModalRef.value);
              Swal.fire({
                text: "Brand has been successfully updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                 refreshData()   
                 router.push({name : "brand-listing"});         
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }

    const submit = async () => {
      
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {
        if (valid) {
          await setBrandData(formData.value);
          console.log("SUBMI FORM")
        } else {
          loading.value = false;
          return false;
        }
      });
    };    

    return {
      tableData,      
      deleteCustomer,
      search,
      searchItems,
      checkedBranch,
      deleteFewCustomers,
      count,      
      getKmpCompList,       
      paginationData,
      changePageChange,
      refreshData,      
      loadingData,
      loading,
      ids,
      setFormData,
      submit,
      formData,
      formRef,
      rules,
      editBrandModalRef,
      active_list_data,
    };  

  }  

});


