

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddUserProfileContact from "@/components/modals/forms/AddUserProfileContact.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, {business_types} from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { mask } from "vue-the-mask";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import JwtService from "@/core/services/JwtService";
import { hideModal } from "@/core/helpers/dom";
import moment from "moment";

export default defineComponent({

  name: "contact-listing",
  directives: { mask },
  props: {    
    id: { type: Object },
    data: {
        type: Object
    },
  },
  components: {
    ExportCustomerModal,
    AddUserProfileContact,     
  },
  methods: {
    onChange() {
      if (this.formData1['pincode'] == "") {
        this.formData1['city'] = "";
        this.formData1['state'] = "";
        this.formData1['country'] = "";
      }
    },
     onClearPan() {
      if (this.formData1['pan_search'] == "" || this.formData1['pan_search'] !== "10") {
        this.formData1['individual_pan'] = "";
      }
    },
  }, 
  
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const editContactModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingPin = ref<boolean>(false);
    const loadingPan = ref<boolean>(false);
    var ids = ref(props['id']);
    const count = ref(0)
    const store = useStore();
    const checkedBranch = ref([]);    
    const loadingData = ref<boolean>(true);
    var formData1 = ref({})
    const router = useRouter();
    const city_value = ref()
    const pin_value = ref()
    const state_value = ref()
    const country_value = ref()
    interface IBranches {
      contact_id,
      contact_name,
      contact_pan,
      city_name,
      state_name,
      contact_designation_name,
      //contact_department_name,
      status: {
        label: string;
        color: string;
      },
      active: {
        label: string;
        color: string;
      }
    }

    const getPanData = async () => {
      loadingPan.value = true;

      const db_data = { pan: formData1.value['pan_search'] };

      await store
        .dispatch(Actions.CUST_GET_USER_PROFILE_PAN_SEARCH, db_data)
        .then(({ data }) => {
          console.log(data);

          if (data.holder_type == 'company') {
            formData1.value['individual_pan'] = data["pan"];
            // formData1.value['city'] = data["holder_type"];
            // formData1.value['state'] = data["holder_name"];
            

            loadingPan.value = false;
          } else {
            formData1.value['individual_pan'] = "";
            

            Swal.fire({
              text: "Sorry, It's Invalid PAN!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPan.value = false;
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Sorry, It's Invalid PAN!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });

          loadingPan.value = false;
        });
    };

     const designation = ref([]);
    const setDesignationdata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            designation.value = body.contact_designation_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const department = ref([]);
    const setDepartmentdata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            department.value = body.department_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const rules = ref({
      individual_name_of_person: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",
        },
      ],
      individual_designation_select: [
        {
          required: true,
          message: "Designation is required",
          trigger: "change",
        },
      ]

    });

    const branch_name = ref([]);
    const setBranchdata = async () => {
      const values = {
       "company_branch_id" : 0,
       "company_id":props.id,
       "page" : 1,
       "records_per_page" : 100
      };
      loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_BRANCH_LIST, values)
          .then(({ data }) => {
            branch_name.value = data.result_list;
            loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const branch_title = ref([]);
    const setBranchTitledata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            branch_title.value = body.branch_title_types;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const title = ref([]);
    const setTitledata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_ADDRESS_TITLE_LIST_DATA)
          .then(({ data }) => {
            title.value = data;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    var paginationData = ref({})
    
    paginationData.value = {      
          start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

    const refreshData =() => {
      paginationData.value['activePage']  = 1;      
      getBranchList(ids)
    }

    const changePageChange = (page) => {
      paginationData.value['activePage']  = page;
      getBranchList(ids)
    }
    
    var tableData = ref<Array<IBranches>>([]);
    
    const getBranchList = async (data) => {

      loadingData.value = true;
      
      var com_id = 0
      if(data._value){
        com_id = data._value
      }else{
        com_id = data 
      }
      
      try {
        
        var values = { 
          "contact_id":0, 
          "company_id" : com_id,
          "page"  : parseInt(paginationData.value['activePage']), 
          "records_per_page" : parseInt(paginationData.value['perPage']) }
          
        await store.dispatch(Actions.CUST_GET_USER_CONTACT_LIST, values).then(({ data }) => {

          tableData.value = ([]);

          //pagination
          paginationData.value['total'] = data.total_records;        
          paginationData.value['start'] = data.records_from
          paginationData.value['end'] = data.records_upto
          paginationData.value['activePage'] = data.page;
          paginationData.value['totPage']  = data.total_pages
          paginationData.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }

          var resultsM = ref<Array<IBranches>>([])
          var status_label = ""
          var status_color = ""
          var active_label = ""
          var active_color = ""

          for (let j = 0; j < data.result_list.length; j++) {          

            if (data.result_list[j]['active']){
                active_label = "Yes";
                active_color = "success";                  
            }else{
                active_label = "No";
                active_color = "danger";
            }
            
            resultsM.value = Array({

              contact_id : data.result_list[j]['contact_id'],
              contact_name : data.result_list[j]['contact_name'],
              contact_pan : data.result_list[j]['contact_pan'],
              city_name : data.result_list[j]['city_name'],
              state_name : data.result_list[j]['state_name'],
              contact_designation_name : data.result_list[j]['contact_designation_name'],
             // contact_department_name : data.result_list[j]['contact_department_name'],
              status: {
                label: status_label,
                color: status_color
              },
              active: {
                label: active_label,
                color: active_color
              }
            })

            tableData.value.splice(j, resultsM.value.length, ...resultsM.value);            
          }

          loadingData.value = false;

        })
        .catch(({ response }) => {

          tableData.value = [];
          loadingData.value = false;

        });
        
      } catch (e) {
        console.log(e);
      }
    };

      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });

      // var tableData = ref<Array<WICompanies>>([]);
      const initCustomers = ref<Array<IBranches>>([]);                      

      onMounted( async () => {
        await getBranchList(props.id)
        await setTitledata();
        await setBranchTitledata();
        await setDesignationdata();
        await setDepartmentdata();
        await setBranchdata();
        await setFormData(props.data);
        // setCurrentPageBreadcrumbs("Branches",[]);
        initCustomers.value.splice(0, tableData.value.length, ...tableData.value);              
      });  

      const deleteFewCustomers = () => {
        checkedBranch.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedBranch.value.length = 0;
      };

      const deleteCustomer = (id) => {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].contact_id === id) {
            tableData.value.splice(i, 1);
          }
        }
      };

      const search = ref<string>("");
      const searchItems = () => {
        tableData.value.splice(0, tableData.value.length, ...initCustomers.value);        
        if (search.value !== "") {
          let results: Array<IBranches> = [];          
          for (let j = 0; j < tableData.value.length; j++) {
            if (searchingFunc(tableData.value[j], search.value)) {              
              results.push(tableData.value[j]);
            }
          }
          tableData.value.splice(0, tableData.value.length, ...results);
        }
      };

      const searchingFunc = (obj, value): boolean => {        
        for (let key in obj) {          
          if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {            
            if (obj[key].indexOf(value) != -1) {
              
              return true;
            }
          }
        }
        return false;
      };   

    const pin = ref([]);
    const getPincodeData = async (search_data) => {
      loadingPin.value = true;

      const db_data = { search_term: search_data };

      if (db_data.search_term) {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_PINCODE_SEARCH, db_data)
          .then(({ data }) => {
            pin.value = data;

            for (let i = 0; i < data.length; i++) {
              formData1.value['city'] = data[i].city_name;
              formData1.value['state'] = data[i].state_name;
              formData1.value['country'] = data[i].country_name;
              formData1.value['city_id'] = data[i].city_id;
              formData1.value['state_id'] = data[i].state_id;
              formData1.value['pincode_id'] = data[i].pincode_id;
              formData1.value['country_id'] = data[i].country_id;

              loadingPin.value = false;
              console.log();
            }
          })

          .catch(({ response }) => {
            Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          });
      }
      // else{

      //     formData.value.city = ""
      //     formData.value.state = ""
      //     formData.value.country = ""
      //     formData.value.city_id = ""
      //     formData.value.state_id = ""
      //     formData.value.pincode_id = ""
      //     formData.value.country_id = ""

      //     Swal.fire({
      //       text: "Sorry, It's Invalid Pincode!",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });

      //     loadingPin.value = false;

      //   }
    };

    const ClickPincodeData = async (pin_details) => {
      formData1.value['city'] = pin_details.city_name
      formData1.value['state'] = pin_details.state_name
      formData1.value['country'] = pin_details.country_name

      city_value.value = pin_details.city_id
      pin_value.value =  pin_details.pincode_id
      state_value.value = pin_details.state_id
      country_value.value = pin_details.country_id
    };

      const branch_value = ref()
      const setFormData = async (data) => {    
      data = data['contact_id'];
        try {

       var values = { 
            "company_id"  : props.id, 
            "contact_id" : data,
            "page"  : 1, 
            "records_per_page" : 1 
            }
        
        await store.dispatch(Actions.CUST_GET_USER_CONTACT_LIST, values).then(({ data }) => {    
          // city_value.value = data.city_id
          // pin_value.value = data.pincode_id
          // state_value.value = data.state_id
          // country_value.value = data.country_id
          console.log("AAAAAAAAAAAAAAAA")
          console.log(data)
          var dob_data , annidob_data , spodob_data
            if(data.contact_dob == '0000-00-00'){
               formData1.value['individual_dob'] = ''
            }
            if(data.contact_dob !== '0000-00-00'){
              dob_data = data.contact_dob
            }

             if(data.contact_anniversary_date == '0000-00-00'){
               formData1.value['individual_anniversary_dob'] = ''
            }
            if(data.contact_anniversary_date !== '0000-00-00'){
              annidob_data = data.contact_anniversary_date
            }

             if(data.contact_spouse_dob == '0000-00-00'){
               formData1.value['individual_spouse_dob'] = ''
            }
            if(data.contact_spouse_dob !== '0000-00-00'){
              spodob_data = data.contact_spouse_dob
            }
          branch_value.value = data.company_branch_id,
          formData1.value = {
            "id": data.contact_id,
           individual_name_of_person : data.contact_name,
           branch_select : data.contact_company_branch_name,
            //company_id: Number(props.id),
           individual_designation_select : data.contact_designation_id,
           individual_department_select : data.contact_department_id,
           individual_email_select : data.contact_email,
           individual_mobile_select : data.contact_mobile_no,
           individual_landline_select : data.contact_landline_no,
           individual_dob : dob_data == '' ? "0000-00-00" :  dob_data,
           individual_anniversary_dob : annidob_data == '' ? "0000-00-00" :  annidob_data,
           individual_spouse_dob : spodob_data == '' ? "0000-00-00" :  spodob_data,
           individual_pan : data.contact_pan,
           // pan_verified_yn: data.pan_search !== "" ? 1 || data.pan_search : 0,
          //  individual_address_line_1 : data.address_line_1,
          //  individual_address_line_2 : data.address_line_2,
          //  pincode: data.pincode,
          //   pincode_id: data.pincode_id,
          //   city: data.city_name,
          //   state: data.state_name,
          //   country: data.country_name,
            };
            console.log('formData1.value')
          console.log(formData1.value)
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }

    const setContactData = async (data) => {
      var user = JSON.parse(JwtService.getToken());
       var ind_dtt = "";
      var ind_anni_dtt = "";
      var ind_spouse_dtt = "";
       try {
          if(data.individual_dob != ""){
            ind_dtt = data.individual_dob
          }   
          
          if(data.individual_anniversary_dob != ""){
            ind_anni_dtt = data.individual_anniversary_dob
          }   

          if(data.individual_spouse_dob != ""){
            ind_spouse_dtt = data.individual_spouse_dob
          }   
        }
        catch{
          console.log('catch');
        }

        if(ind_dtt ){
          console.log('aa');
          console.log(ind_dtt);
        }
        else if(data.individual_dob == undefined){
          //console.log("indi dob undefined")
          ind_dtt = ""
        }
        
        else{

          var dt = ("0" + data.individual_dob.getDate()).slice(-2)
          var mo = ("0" + (data.individual_dob.getMonth() + 1)).slice(-2)
          var yy = data.individual_dob.getFullYear()
          ind_dtt = (yy+'-'+mo+'-'+dt);
        };

        if(ind_anni_dtt){
          console.log('bb');
          console.log(ind_anni_dtt);
          
        }
        else if(data.individual_anniversary_dob == undefined){
          //console.log("indi anni dob undefined")
          ind_anni_dtt = ""
        }
        
        else{

          var dt_ani = ("0" + data.individual_anniversary_dob.getDate()).slice(-2)
          var mo_ani = ("0" + (data.individual_anniversary_dob.getMonth() + 1)).slice(-2)
          var yy_ani = data.individual_anniversary_dob.getFullYear()
          ind_anni_dtt = (yy_ani+'-'+mo_ani+'-'+dt_ani);
        };

        if(ind_spouse_dtt){
          console.log('cc');
          
        }
        else if(data.individual_spouse_dob == undefined){
         // console.log("indi spouse dob undefined")
          ind_spouse_dtt = ""
        }
        
        else{

          var dt_spouse = ("0" + data.individual_spouse_dob.getDate()).slice(-2)
          var mo_spouse = ("0" + (data.individual_spouse_dob.getMonth() + 1)).slice(-2)
          var yy_spouse = data.individual_spouse_dob.getFullYear()
          ind_spouse_dtt = (yy_spouse+'-'+mo_spouse+'-'+dt_spouse);
        };
        
        
      const db_data = {
        contact_id : data.id,
        contact_name: data.individual_name_of_person,
        company_branch_id: branch_value.value || data.branch_select || "",
        company_id: Number(props.id),
        designation_id: data.individual_designation_select,
        department_id: data.individual_department_select,
        email: data.individual_email_select || "",
        mobile_no: data.individual_mobile_select || "",
        landline_no: data.individual_landline_select || "",
        contact_dob: (ind_dtt == "" ? "0000-00-00" : (moment(String(formData1.value['individual_dob'])).format("YYYY-MM-DD"))),
        contact_anniversary_date: (ind_anni_dtt == "" ? "0000-00-00" : (moment(String(formData1.value['individual_anniversary_dob'])).format("YYYY-MM-DD"))),
        contact_spouse_dob: (ind_spouse_dtt == "" ? "0000-00-00" : (moment(String(formData1.value['individual_spouse_dob'])).format("YYYY-MM-DD"))),
        contact_pan: data.individual_pan || "",
        pan_verified_yn: data.pan_search !== "" ? 1 || data.pan_search : 0,
        // address_line_1: data.individual_address_line_1 || "",
        // address_line_2: data.individual_address_line_2 || "",
        // pincode_id: pin_value.value || data.pincode_id ,
        user_id: user.user_id,
      };

      await store
        .dispatch(Actions.CUST_GET_USER_CONTACT_UPDATE, db_data)
        .then(({ data }) => {
          if (data.is_success) {
            setTimeout(() => {
              loading.value = false;
              hideModal(editContactModalRef.value);
              Swal.fire({
                text: "Contact has been successfully updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                refreshData()
                router.push({name : "contact-listing"});
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          return false;
        });
    }
      
      const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setContactData(formData1.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };
       
    return {
      tableData,      
      deleteCustomer,
      search,
      searchItems,
      checkedBranch,
      deleteFewCustomers,
      count,      
      getBranchList,       
      paginationData,
      changePageChange,
      refreshData,
      branch_title,
      designation,
      department,
      title,
      branch_name,
      setBranchdata,
      setBranchTitledata,
      setDesignationdata,
      setDepartmentdata,
      setTitledata,
      submit,
      setFormData,
      loadingData,
      loading,
      loadingPin,
      loadingPan,
      ids,
      pin,
      getPincodeData,
      ClickPincodeData,
      formData1,
      editContactModalRef,
      formRef,
      rules,
      getPanData,
    };  

  }  

});




