
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {designation_list, active} from "@/core/data/genericData";
import axios from "axios";
import JwtService from "@/core/services/JwtService";
import {mask} from 'vue-the-mask'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";


export default defineComponent({
  name: "add-branch-modal",
  directives: {mask},
  props: {    
    id: { required: true }
  },
  components: {
    
    },
  setup(props) {
    const active_list_data = active;
    const store = useStore();
    const designation_item = designation_list;    
    const formRef = ref<null | HTMLFormElement>(null);
    const addBrandModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingDin = ref<boolean>(false);
    const router = useRouter();    

    const formData = ref({
      name: "",      
      plant_select: "",
      active: 1,
    });    

    onMounted(async () => {
      //await setPlantdata()
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",          
        },        
      ],
      
    });

    // const plant = ref([]);
    // const setPlantdata = async () => {
    //   const values = {
    //   "company_branch_id" : 0,
    //   "company_id":props.id,
    //   "page" : 1,
    //   "records_per_page" : 100
    //   };
    //   loading.value = true;
    //   try {
    //     await store
    //       .dispatch(Actions.CUST_GET_PLANT_LIST, values)
    //       .then(({ data }) => {
    //         plant.value = data.result_list;
    //         loading.value = false;
    //       })
    //       .catch(({ response }) => {
    //         console.log(response);
    //       });
    //   } catch (e) {
    //     console.log(e);
    //   }
    // };

   
    const setBrandData = async (data) => {

      var user = JSON.parse(JwtService.getToken());

      const db_data = {
        "brand_id":0,
        "brand_name": data.name,
        "company_id": props.id,
        //"company_branch_id": data.plant_select,
        "active": data.active,
        "user_id": user.user_id,        
        }

      await store.dispatch(Actions.CUST_ADD_USER_BRAND, db_data).then(({ data }) => {

        if (data){

            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "Brand has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addBrandModalRef.value);   
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }

    const submit = async () => {
      
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {
        if (valid) {
          await setBrandData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };    

    return {
      formData,
      designation_item,
      rules,
      submit,
      formRef,
      loading,
      loadingDin,
      addBrandModalRef,
      active_list_data,
     // plant,
      //setPlantdata,
    };
  },
});
