
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import  { user_gst_type} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { gst_type, gst_return_frequency } from "@/core/data/genericData";

export default defineComponent({
  name: "add-branch-modal",
  directives: { mask },
  props: {
    id: { required: true },
  },
  components: {},
  methods: {
    onChange() {
      if (this.formData.pincode == "") {
        this.formData.city = "";
        this.formData.state = "";
        this.formData.country = "";
      }
    },
    onClearGst() {
      if (
        this.formData.gstin_search == "" ||
        this.formData.gstin_search !== "15"
      ) {
        this.formData.pincode = "";
        this.formData.city = "";
        this.formData.state = "";
        this.formData.country = "";
        this.formData.jurisdiction = "";
        this.formData.legal_name = "";
        this.formData.address_line_1 = "";
        this.formData.gst_type = "";
        this.formData.gstin = "";
        this.formData.state_tin = "";
          // this.formData.basic_name = "";
          // this.formData.business_type_select = "";
      }
    },
  }, 
  setup(props) {
    const user_gst_type_data = user_gst_type;
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const addBranchModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingPin = ref<boolean>(false);
    const router = useRouter();
    const type_list_data = gst_type;
    const freq_list_data = gst_return_frequency;

    const formData = ref({
      name: "",
      gstin_search: "",
      business_landline: "",
      business_emails: "",
      business_mobile: "",
      address_line_1: "",
      address_line_2: "",
      basic_name: "",
      legal_name: "",
      business_type_select: "",
      mobile_list: ref<any>([]),
      email_list: ref<any>([]),
      landline_list: ref<any>([]),
      pincode: "",
      pincode_id: "",
      city: "",
      state: "",
      country: "",
      city_id: "",
      state_id: "",
      country_id: "",
      gstin: "",
      state_tin: "",
      gst_type: "",
      jurisdiction: "",
    });
    
    const onMobileEnter = () => {
      debugger;
      if (
        /^[789]\d{9}$/.test(formData.value["business_mobile"]) &&
        !formData.value["mobile_list"].includes(
          formData.value["business_mobile"]
        )
      ) {
        formData.value["mobile_list"].push(formData.value["business_mobile"]);
        formData.value["business_mobile"] = "";
      }
    };

    const onLandLineEnter = () => {
      debugger;
      if (
        /\d{5}([- ]*)\d{6}/.test(formData.value["business_landline"]) &&
        !formData.value["landline_list"].includes(
          formData.value["business_landline"]
        )
      ) {
        formData.value["landline_list"].push(
          formData.value["business_landline"]
        );
        formData.value["business_landline"] = "";
      }
    };

    const onEmailEnter = () => {
      debugger;
      if (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          formData.value["business_emails"]
        ) &&
        !formData.value["email_list"].includes(
          formData.value["business_emails"]
        )
      ) {
        formData.value["email_list"].push(formData.value["business_emails"]);
        formData.value["business_emails"] = "";
      }
    };

    const getGstData = async () => {
      loadingPin.value = true;

      const db_data = { gstin: formData.value.gstin_search };

      await store
        .dispatch(Actions.CUST_GET_USER_PROFILE_GSTIN_SEARCH, db_data)
        .then(({ data }) => {
          console.log(data);

          if (data) {
            formData.value.city = data["city_name"];
            formData.value.state = data["state_name"];
            formData.value.country = "India";
            formData.value.state_tin = data["tin"];
            formData.value.city_id = data["city_id"];
            formData.value.state_id = data["state_id"];
            formData.value.pincode_id = data["pincode_id"];
            formData.value.pincode = data["pincode"];
            formData.value.country_id = data["country_id"];
            formData.value.business_type_select = data["business_type_id"];
            formData.value.basic_name = data["legal_business_name"];
            formData.value.legal_name = data["legal_business_name"];
            formData.value.jurisdiction = data["state_jurisdiction"];
            formData.value.address_line_1 = data["address"];
            formData.value.gst_type = data["gst_type"];
            formData.value.gstin = data["gstin"];
            formData.value.state_tin = data["state_tin"];

            loadingPin.value = false;
          } else {
            formData.value.city = "";
            formData.value.state = "";
            formData.value.country = "";
            formData.value.state_tin = "";
            formData.value.city_id = "";
            formData.value.state_id = "";
            formData.value.pincode_id = "";
            formData.value.country_id = "";

            Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Sorry, It's Invalid Pincode!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });

          loadingPin.value = false;
        });
    };


    onMounted(async () => {
      //const db_data = {}
      await setTitledata();
      await setBranchTitledata();
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Branch Name is required",
          trigger: "change",
        },
      ],

    });

    const branch_title = ref([]);
    const setBranchTitledata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            branch_title.value = body.branch_title_types;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

   const pin = ref([]);
    const getPincodeData = async (search_data) => {
      loadingPin.value = true;

      const db_data = { search_term: search_data };

      if (db_data.search_term) {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_PINCODE_SEARCH, db_data)
          .then(({ data }) => {
            pin.value = data;

            for (let i = 0; i < data.length; i++) {
              formData.value.city = data[i].city_name;
              formData.value.state = data[i].state_name;
              formData.value.country = data[i].country_name;
              formData.value.city_id = data[i].city_id;
              formData.value.state_id = data[i].state_id;
              formData.value.pincode_id = data[i].pincode_id;
              formData.value.country_id = data[i].country_id;

              loadingPin.value = false;
              console.log();
            }
          })

          .catch(({ response }) => {
            Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          });
      }
      // else{

      //     formData.value.city = ""
      //     formData.value.state = ""
      //     formData.value.country = ""
      //     formData.value.city_id = ""
      //     formData.value.state_id = ""
      //     formData.value.pincode_id = ""
      //     formData.value.country_id = ""

      //     Swal.fire({
      //       text: "Sorry, It's Invalid Pincode!",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });

      //     loadingPin.value = false;

      //   }
    };

    const ClickPincodeData = async (pin_details) => {
      formData.value.city = pin_details.city_name;
      formData.value.state = pin_details.state_name;
      formData.value.country = pin_details.country_name;
      formData.value.city_id = pin_details.city_id;
      formData.value.state_id = pin_details.state_id;
      formData.value.pincode_id = pin_details.pincode_id;
      formData.value.country_id = pin_details.country_id;
    };

    const title = ref([]);
    const setTitledata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_ADDRESS_TITLE_LIST_DATA)
          .then(({ data }) => {
            title.value = data;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    async function setBranchData(data) {
      var user = JSON.parse(JwtService.getToken());

      const db_data = {
        company_branch_id: 0,
        branch_name: data.name,
        company_id: Number(props.id),
        branch_title_type_id: data.branch_title_select || 0,
        capacity_in_mt : 0,
        capacity_duration : "",
        business_email_json: data.email_list,
        business_mobile_json: data.mobile_list,
        business_landline_json: data.landline_list,
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        pincode_id: data.pincode_id || 0,
        // city_id: data.city_id,
        // state_id: data.state_id,
        //country_id: 101,
        gstin: data.gstin || "",
        state_tin: data.state_tin || "",
        gst_type: data.gst_type || "",
        jurisdiction: data.jurisdiction || "",
        branch_verified_yn :0,
        gst_return_frequency: data.gst_return_frequency,
        gstin_verified_yn: data.gstin_search !== "" ? 1 || data.gstin_search : 0,
        verified_yes_no: false,
        active: 1,
        user_id: user.user_id,
      };

      await store
        .dispatch(Actions.CUST_ADD_BRANCH, db_data)
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              loading.value = false;
              //
              Swal.fire({
                text: "Branch has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addBranchModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          return false;
        });
    }

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setBranchData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      pin,
      getPincodeData,
      ClickPincodeData,
      getGstData,
      formRef,
      loading,
      loadingPin,
      branch_title,
      setBranchTitledata,
      user_gst_type_data,
      addBranchModalRef,
      freq_list_data,
      type_list_data,
      setTitledata,
      onMobileEnter,
      onEmailEnter,
      onLandLineEnter,
      title,
    };
  },
});
