

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddUserProfileBank from "@/components/modals/forms/AddUserProfileBank.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, {business_types} from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import JwtService from "@/core/services/JwtService";
import { mask } from "vue-the-mask";

export default defineComponent({

  name: "bank-listing",
  directives: { mask },
  props: {    
    id: { type: Object },
    data: {
        type: Object
    },
    
  },
  components: {
    ExportCustomerModal,
    AddUserProfileBank,     
  },
  methods: {
    onClearGst(){
      console.log("hello")
    }
  },
  
  setup(props) {

    var ids = ref(props['id']);
    const count = ref(0)
    const store = useStore();
    const checkedBranch = ref([]);    
    const city_value = ref()
    const pin_value = ref()
    const state_value = ref()
    const country_value = ref()
    const loadingData = ref<boolean>(true);
    const formRef = ref<null | HTMLFormElement>(null);
    const editBankModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingPin = ref<boolean>(false);
    const router = useRouter();

    interface IBranches {
      bank_details_id,
      beneficiary_name,
      bank,
      BRANCH,
      CENTRE,
      DISTRICT,
      STATE,
      status: {
        label: string;
        color: string;
      },
      active: {
        label: string;
        color: string;
      }
    }

    const rules = ref({
      beneficiary_name: [
        {
          required: true,
          message: "Beneificary Name is required",
          trigger: "change",
        },
      ],
      acc_no: [
        {
          required: true,
          message: "Account No. is required",
          trigger: "change",
        },
      ],
      acc_type: [
        {
          required: true,
          message: "Account Type is required",
          trigger: "change",
        },
      ],
      ifsc_search: [
        {
          required: true,
          message: "IFSC is required",
          trigger: "change",
          min: 11,
          max: 11,
          pattern:"^[a-zA-Z0-9]+$",
        },
      ],
      // ifsc_id: [
      //   {
      //     required: true,
      //     message: "Branch Name is required",
      //     trigger: "change",
      //   },
      // ],
      address: [
        {
          required: true,
          message: "Address is required",
          trigger: "change",
        },
      ],
      branch_name: [
        {
          required: true,
          message: "Branch Name is required",
          trigger: "change",
        },
      ],
      bank_name: [
        {
          required: true,
          message: "Bank Name is required",
          trigger: "change",
        },
      ],
      city: [
        {
          required: true,
          message: "City is required",
          trigger: "change",
        },
      ],
      state: [
        {
          required: true,
          message: "State is required",
          trigger: "change",
        },
      ],
      micr: [
        {
          required: true,
          message: "Micr is required",
          trigger: "change",
        },
      ],

    });

    var paginationData = ref({})
    
    paginationData.value = {      
          start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

    const refreshData =() => {
      paginationData.value['activePage']  = 1;      
      getBranchList(ids)
    }

    const changePageChange = (page) => {
      paginationData.value['activePage']  = page;
      getBranchList(ids)
    }
    
    var tableData = ref<Array<IBranches>>([]);
    
    const getBranchList = async (data) => {

      loadingData.value = true;
      
      var com_id = 0
      if(data._value){
        com_id = data._value
      }else{
        com_id = data 
      }
      
      try {
        
        var values = { 
         "company_id"  : com_id, 
        "bank_account_id" : 0,
        "page"  : parseInt(paginationData.value['activePage']), 
        "records_per_page" : parseInt(paginationData.value['perPage']) 
        }
        
        await store.dispatch(Actions.CUST_GET_USER_BANK_LIST, values).then(({ data }) => {

          tableData.value = ([]);

          //pagination
          // paginationData.value['total'] = data.total_records;        
          // paginationData.value['start'] = data.records_from
          // paginationData.value['end'] = data.records_upto
          // paginationData.value['activePage'] = data.page;
          // paginationData.value['totPage']  = data.total_pages
          // paginationData.value['pageRange'] = []
          // for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
          //   paginationData.value['pageRange'].push(index)
          // }

          var resultsM = ref<Array<IBranches>>([])
          var status_label = ""
          var status_color = ""
          var active_label = ""
          var active_color = ""

          for (let j = 0; j < data.result_list.length; j++) {          

            if (data.result_list[j]['active']){
                active_label = "Yes";
                active_color = "success";                  
            }else{
                active_label = "No";
                active_color = "danger";
            }
            
            resultsM.value = Array({

              bank_details_id : data.result_list[j]['bank_details_id'],
              beneficiary_name : data.result_list[j]['beneficiary_name'],
              bank : data.result_list[j]['bank'],
              BRANCH : data.result_list[j]['BRANCH'],
              STATE : data.result_list[j]['STATE'],
              CENTRE : data.result_list[j]['CENTRE'],
              DISTRICT : data.result_list[j]['DISTRICT'],
              status: {
                label: status_label,
                color: status_color
              },
              active: {
                label: active_label,
                color: active_color
              }
            })

            tableData.value.splice(j, resultsM.value.length, ...resultsM.value);            
          }

          loadingData.value = false;

        })
        .catch(({ response }) => {

          tableData.value = [];
          loadingData.value = false;

        });
        
      } catch (e) {
        console.log(e);
      }
    };

      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });

      // var tableData = ref<Array<WICompanies>>([]);
      const initCustomers = ref<Array<IBranches>>([]);                      

      onMounted( async () => {
        await getBranchList(props.id)
        await setFormData(props.data);
        console.log("SETFORM DATAaaaaaaaaaaaaaa")
          console.log(props.data)
        // setCurrentPageBreadcrumbs("Branches",[]);
        initCustomers.value.splice(0, tableData.value.length, ...tableData.value);              
      });  

      const deleteFewCustomers = () => {
        checkedBranch.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedBranch.value.length = 0;
      };

      const deleteCustomer = (id) => {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].bank_details_id === id) {
            tableData.value.splice(i, 1);
          }
        }
      };

      const search = ref<string>("");
      const searchItems = () => {
        tableData.value.splice(0, tableData.value.length, ...initCustomers.value);        
        if (search.value !== "") {
          let results: Array<IBranches> = [];          
          for (let j = 0; j < tableData.value.length; j++) {
            if (searchingFunc(tableData.value[j], search.value)) {              
              results.push(tableData.value[j]);
            }
          }
          tableData.value.splice(0, tableData.value.length, ...results);
        }
      };

      const searchingFunc = (obj, value): boolean => {        
        for (let key in obj) {          
          if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {            
            if (obj[key].indexOf(value) != -1) {
              
              return true;
            }
          }
        }
        return false;
      };  

      const getGstData = async () => {
      loadingPin.value = true;

      const db_data = { search_term: formData1.value['ifsc_search'] };

      await store
        .dispatch(Actions.CUST_GET_USER_PROFILE_IFSC_SEARCH, db_data)
        .then(({ data }) => {
          console.log(data);
          for (let i = 0; i < data.length; i++) {
              formData1.value['city'] = data[i].city;
              formData1.value['state'] = data[i].state;
              formData1.value['micr'] = data[i].micr;
              formData1.value['bank_name'] = data[i].bank;
              formData1.value['branch_name'] = data[i].branch;
              formData1.value['ifsc_id'] = data[i].ifsc_id;
              formData1.value['address'] = data[i].address;
              loadingPin.value = false;
              console.log();
            }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Sorry, It's Invalid Ifsc!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          
          loadingPin.value = false;
        });
    };

     const setBranchData = async (data) => {
      var user = JSON.parse(JwtService.getToken());

      const db_data = {
        "bank_account_id" : data.id,
        "company_id" : Number(props.id),
        "beneficiary_name" : data.beneficiary_name,
        "account_no" : data.acc_no,
        "account_type" : data.acc_type,
        "ifsc_id" : data.ifsc_id,
        "verified_yes_no": false,
        "user_id": user.user_id,
      };

      await store
        .dispatch(Actions.CUST_ADD_USER_BANK, db_data)
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              loading.value = false;
               hideModal(editBankModalRef.value);
              Swal.fire({
                text: "Bank has been successfully updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                 refreshData()
                router.push({name : "bank-listing"});
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          return false;
        });
    }

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setBranchData(formData1.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

      var formData1 = ref({})
      const setFormData = async (data) => {   
      data = data['bank_details_id'];
        try {

       var values = { 
            "company_id"  : props.id, 
            "bank_account_id" : data,
            }
        
        await store.dispatch(Actions.CUST_GET_USER_BANK_LIST, values).then(({ data }) => {    
          console.log("SETFORM DATA")
          console.log(data)
          city_value.value = data.city_id
          pin_value.value = data.pincode_id
          state_value.value = data.state_id
          country_value.value = data.country_id
          
          formData1.value = {
            "id": data.bank_account_id,
            beneficiary_name : data.beneficiary_name,
            acc_no : data.account_no,
            acc_type : data.account_type,
            ifsc_search : data.IFSC,
            ifsc_id : data.ifsc_id,
            bank_name : data.bank,
            branch_name : data.BRANCH,
            // pincode: data.pincode,
            // pincode_id: data.pincode_id,
            city: data.CITY,
            micr : data.MICR,
            state: data.STATE,
            country: data.country_name,
            // gstin: data.gstin,
            //state_tin: data.state_tin,
            address : data.ADDRESS,
           
            };
            console.log('formData1.value')
          console.log(formData1.value)
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }
       
    return {
      tableData,  
      rules,
      submit,
      getGstData,
      formData1,
      formRef,    
      loading,
      loadingPin,
      editBankModalRef,
      deleteCustomer,
      setFormData,
      search,
      searchItems,
      checkedBranch,
      deleteFewCustomers,
      count,      
      getBranchList,       
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      ids,
    };  

  }  

});




