

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, {business_types} from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import {active} from "@/core/data/genericData";
import { useRouter } from "vue-router";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {mask} from 'vue-the-mask'

export default defineComponent({
  name: "apps-document",
  directives: {mask},
  props: {    
    id: { type: Object }
    
  },
  components: {
    
  },
  
  

  setup(props) {
    var ids = ref(props['id']);
    //const active_list_data = active;
    const formRef = ref<null | HTMLFormElement>(null);
    const addNewsModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const router = useRouter(); 
    const searchTearm = ref('');
    const loadingData = ref<boolean>(true); 

     const formData = ref({
      doc_select: "",
      textbox_select : "",
    });    

    onMounted( async () => {
      //const db_data = {}
      setCurrentPageBreadcrumbs("Documents", []);
      await getDocumentList("")
    });
    
    const doc_label = ref();
    const vmask = ref();
    function onClick(data){
      doc_label.value = data
      
    }
    

    let rawImgDoc = ref() ;
    function encodeImageDoctoBase64L(element) {
        const image = element.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        
        reader.onload = (event) => {       
        var image_encode = String(reader.result)
        if (image_encode){
           var image_slice = image_encode.split(",")[1]
            rawImgDoc.value = {
          file_name:element.target.files[0].name,
          file_data: image_slice,
          }
        }
        else{
          rawImgDoc.value = {
          file_name:element.target.files[0].name,
          file_data:reader.result,
          }
        }
       
       };
      
      }

      const doc = ref([]);
      const doc_id = ref([]);
       const DocumentData = async (data) => {
        
        
        if(allData.value['result_list'] == undefined){ 
           loading.value = true;
            try {
              const db_data = {
              search_term: data,
              type: 1,
            };
              if(db_data.search_term.length >= 3){
              await store.dispatch(Actions.DOCUMENT_DP, db_data).then(({ data }) => {
              doc.value = data;
              doc_id.value = data[0].document_id
              
              loading.value = false;
            
            })
            .catch(({ e }) => {
              console.log(e);        
            });
          }
          
          } 
              
          catch (e) {
            console.log(e);
            }
          }
        

        else{
        for(let i = 0 ; i< allData.value['result_list'].length ; i++){
          if(allData.value['result_list'][i]['document_id']){
              if(allData.value['result_list'][i]['company_document_status'] == 1){
                  
            loading.value = true;
            try {
              const db_data = {
              search_term: data,
              type: 0,
            };
              if(db_data.search_term.length >= 3){
              await store.dispatch(Actions.DOCUMENT_DP, db_data).then(({ data }) => {
              doc.value = data;
              doc_id.value = data[0].document_id;
              
              (document.getElementById('option_id') as HTMLSelectElement).hidden = true
              
              loading.value = false;
            
            })
            .catch(({ e }) => {
              console.log(e);        
            });
          }
          
          } 
              
          catch (e) {
            console.log(e);
            }
          }
        }
        else{
          loading.value = true;
            try {
              const db_data = {
              search_term: data,
              type: 0,
            };
              if(db_data.search_term.length >= 3){
              await store.dispatch(Actions.DOCUMENT_DP, db_data).then(({ data }) => {
              doc.value = data;
              doc_id.value = data[0].document_id;
              
              (document.getElementById('option_id') as HTMLSelectElement).hidden = false
              
              loading.value = false;
            
            })
            .catch(({ e }) => {
              console.log(e);        
            });
          }
          
          } 
          catch (e) {
            console.log(e);
            }
        }
      }
        }
    }

      const PanVerification = async (uploaded_data) => {
      //   const db_data = {
      //     "company_id":uploaded_data.company_id,
      //     "company_document_id":uploaded_data.company_document_id,
      //     "document_id":uploaded_data.document_id,
      //     "company_pan":formData.value['textbox_select'],
      //     "pan_file_path":uploaded_data.file_path,
      //     "pan_file_name":uploaded_data.file_name
      //   }
      //   try {
      //     await store.dispatch('ActionsErp.PAN_VERIFICATION', db_data).then(({ data }) => {
      //     if (data.is_success){
      //       // UploadImage(data.category_id)

      //       setTimeout(() => {
      //         loading.value = false;
      //         Swal.fire({
      //           text: "Pan verified successfully.",
      //           icon: "success",
      //           buttonsStyling: false,
      //           confirmButtonText: "Ok, got it!",
      //           customClass: {
      //             confirmButton: "btn btn-primary",
      //           },
      //         }).then(() => {
      //           hideModal(addNewsModalRef.value);  
      //           router.go(0)           
      //         });
      //       }, 2000)          

      //     }
      //     else {

      //       loading.value = false;

      //       Swal.fire({
      //         text: "Sorry, looks like there are some errors detected, please try again.",
      //         icon: "error",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn btn-primary",
      //         },
      //       })  
      //       return false;
      //     }
         
      //   })
      //   .catch(({ e }) => {
      //     console.log(e);        
      //   });
        
      // } catch (e) {
      //   console.log(e);
      // }

    }

   
    const rules = ref({
      doc_select: [
        {
          required: true,
          message: "Document is required",
          trigger: "change", 
          
        },        
      ],   
      textbox_select : [
        {
          required: true,
          message: "Please enter",
          trigger: "change",  
          
        },        
      ],   
      
    });

    interface Document {
      company_name,  
      document_name,
      company_document_upload_name,    
      company_document_no,
      company_document_status,
      status: {
        label: string;
        color: string;
      },
    }

    var paginationData = ref({})
    
    paginationData.value = {      
          start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

    const refreshData =() => {
      paginationData.value['activePage']  = 1;
      getDocumentList("")
    }

    const changePageChange = (page) => {
      
      paginationData.value['activePage']  = page;
      getDocumentList("")
    }
    
    var tableData = ref<Array<Document>>([]);
    var allData = ref<Array<Document>>([]);
      const getDocumentList = async (data) => {
      
      loadingData.value = true;
      try {
        var values = { 
        "company_id" : props.id, 
        "company_document_id":0, 
        "page"  : parseInt(paginationData.value['activePage']), 
        "records_per_page" : parseInt(paginationData.value['perPage']) }

        await store.dispatch(Actions.DOCUMENT_COMPANY_LIST, values).then(({ data }) => {
          allData.value = data;
          tableData.value = ([]);

          //pagination
          paginationData.value['total'] = data.total_records;        
          paginationData.value['start'] = data.records_from
          paginationData.value['end'] = data.records_upto
          paginationData.value['activePage'] = data.page;
          paginationData.value['totPage']  = data.total_pages
          paginationData.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
          
          var resultsM = ref<Array<Document>>([])
          var status_label = ""
          var status_color = ""

          for (let j = 0; j < data.result_list.length; j++) {

            if (data.result_list[j]['company_document_status']){
                status_label = "Verified";
                status_color = "success";
            }else{
                status_label = "Unverified";
                status_color = "danger";
            }

            resultsM.value = Array({
              status: {
                label: status_label,
                color: status_color
              },
              company_name : data.result_list[j]['company_name'],
              document_name : data.result_list[j]['document_name'],
              company_document_upload_name : data.result_list[j]['company_document_url'],
              company_document_no : data.result_list[j]['company_document_no'],
              company_document_status: data.result_list[j]['company_document_status'],
            })

            tableData.value.splice(j, resultsM.value.length, ...resultsM.value);            
          }

          loadingData.value = false;

        })
        .catch(({ response }) => {

          tableData.value = [];
          loadingData.value = false;

        });
        
      } catch (e) {
        console.log(e);
      }
    };

    const setDocumentData = async (data) => {
     
      const db_data = {
        "user_id":1,
        "company_id":props.id,
        "document_id":data.doc_select,
        "document_no":data.textbox_select,
        "document_expiry_date":"",
        "file_name":rawImgDoc.value.file_name,
        "file_data": rawImgDoc.value.file_data,
        }
        
        await store.dispatch(Actions.DOCUMENT_COMPANY_UPLOAD, db_data).then(({ data }) => {
          if (data.is_success){
            
            // if(data.document_id == 1)
            // {

            //   PanVerification(data)
            // }
            
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "Document uploaded successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addNewsModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }

    const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setDocumentData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });    

    };

    return {      
      formData,      
      rules,
      submit,
      formRef,
      loading,
      addNewsModalRef,
      encodeImageDoctoBase64L,
      DocumentData,
      onClick,
      doc_label,
      tableData,      
      loadingData,
      paginationData,
      changePageChange,
      refreshData,
      getDocumentList,
      doc,
      length,
      rawImgDoc,
      allData,
      doc_id
    };
  },
});
