
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import  { user_gst_type} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { gst_type, gst_return_frequency, acc_type } from "@/core/data/genericData";

export default defineComponent({
  name: "add-branch-modal",
  directives: { mask },
  props: {
    id: { required: true },
  },
  components: {},
  methods: {
 onClearGst() {
      if (
        this.formData1.ifsc_search == "" ||
        this.formData1.ifsc_search !== "11"
      ) {
        this.formData1.city = "";
        this.formData1.state = "";
        this.formData1.micr = "";
        this.formData1.bank_name = "";
        this.formData1.branch_name = "";
        this.formData1.address= "";
        this.formData1.ifsc_id= "";
      }
    },
   }, 
  setup(props) {
    const user_gst_type_data = user_gst_type;
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const addBankModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingPin = ref<boolean>(false);
    const router = useRouter();
    const type_list_data = gst_type;
    const freq_list_data = gst_return_frequency;
    const account_type = acc_type;
    const formData = ref({
      // name: "",
      // gstin_search: "",
      // business_landline: "",
      // business_emails: "",
      // business_mobile: "",
      // address_line_1: "",
      // address_line_2: "",
      // basic_name: "",
      // legal_name: "",
      // business_type_select: "",
      // mobile_list: ref<any>([]),
      // email_list: ref<any>([]),
      // landline_list: ref<any>([]),
      // pincode: "",
      // pincode_id: "",
      // city: "",
      // state: "",
      // country: "",
      // city_id: "",
      // state_id: "",
      // country_id: "",
      // gstin: "",
      // state_tin: "",
      // gst_type: "",
      // jurisdiction: "",
    });
    

     const formData1 = ref({
      beneficiary_name: "",
      acc_no: "",
      acc_type: "",
      ifsc_search: "",
      ifsc_id: "",
      address: "",
      branch_name: "",
      bank_name: "",
      city: "",
      state: "",
      micr: "",
    });

    const rules = ref({
      beneficiary_name: [
        {
          required: true,
          message: "Beneificary Name is required",
          trigger: "change",
        },
      ],
      acc_no: [
        {
          required: true,
          message: "Account No. is required",
          trigger: "change",
        },
      ],
      acc_type: [
        {
          required: true,
          message: "Account Type is required",
          trigger: "change",
        },
      ],
      ifsc_search: [
        {
          required: true,
          message: "IFSC is required",
          trigger: "change",
          min: 11,
          max: 11,
          pattern:"^[a-zA-Z0-9]+$",
        },
      ],
      // ifsc_id: [
      //   {
      //     required: true,
      //     message: "Branch Name is required",
      //     trigger: "change",
      //   },
      // ],
      address: [
        {
          required: true,
          message: "Address is required",
          trigger: "change",
        },
      ],
      branch_name: [
        {
          required: true,
          message: "Branch Name is required",
          trigger: "change",
        },
      ],
      bank_name: [
        {
          required: true,
          message: "Bank Name is required",
          trigger: "change",
        },
      ],
      city: [
        {
          required: true,
          message: "City is required",
          trigger: "change",
        },
      ],
      state: [
        {
          required: true,
          message: "State is required",
          trigger: "change",
        },
      ],
      micr: [
        {
          required: true,
          message: "Micr is required",
          trigger: "change",
        },
      ],

    });


    const getGstData = async () => {
      loadingPin.value = true;

      const db_data = { search_term: formData1.value.ifsc_search };

      await store
        .dispatch(Actions.CUST_GET_USER_PROFILE_IFSC_SEARCH, db_data)
        .then(({ data }) => {
          console.log(data);
          console.log('formData1.value.city')
          console.log(formData1.value.city)
          for (let i = 0; i < data.length; i++) {
              formData1.value.city = data[i].city;
              formData1.value.state = data[i].state;
              formData1.value.micr = data[i].micr;
              formData1.value.bank_name = data[i].bank;
              formData1.value.branch_name = data[i].branch;
              formData1.value.ifsc_id = data[i].ifsc_id;
              formData1.value.address = data[i].address;
              loadingPin.value = false;
              console.log();
            }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Sorry, It's Invalid Ifsc!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          
          loadingPin.value = false;
        });
    };

    

   

    onMounted(async () => {
      //const db_data = {}
      
    });

    

   
    async function setBranchData(data) {
      var user = JSON.parse(JwtService.getToken());

      const db_data = {
        "bank_account_id" : 0,
        "company_id" : props.id,
        "beneficiary_name" : data.beneficiary_name,
        "account_no" : data.acc_no,
        "account_type" : data.acc_type,
        "ifsc_id" : data.ifsc_id,
        "verified_yes_no": false,
        "user_id": user.user_id,
      };

      await store
        .dispatch(Actions.CUST_ADD_USER_BANK, db_data)
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              loading.value = false;
              //
              Swal.fire({
                text: "Bank has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addBankModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          return false;
        });
    }

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setBranchData(formData1.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    return {
      
      formData1,
      rules,
      submit,
      getGstData,
      formRef,
      loading,
      loadingPin,
      user_gst_type_data,
      addBankModalRef,
      freq_list_data,
      type_list_data,
      account_type,
    };
  },
});
