

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddUserProfileKmp from "@/components/modals/forms/AddUserProfileKmp.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, {business_types} from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import {designation_list} from "@/core/data/genericData";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import {mask} from 'vue-the-mask'
import JwtService from "@/core/services/JwtService";

export default defineComponent({

  name: "kmp-listing",
  directives: {mask},
  props: {    
    id: { required: true },
    data: {
        type: Object
    },
  },
  components: {
    ExportCustomerModal,
    AddUserProfileKmp, 
  },
  
  setup(props) {
    
    var ids = ref(props.id);
    const count = ref(0)
    const router = useRouter();   
    const store = useStore();
    const checkedBranch = ref([]);  
    const loadingData = ref<boolean>(true);  
    const formRef = ref<null | HTMLFormElement>(null);
    const editkmpModalRef = ref<null | HTMLElement>(null);
    const designation_item = designation_list; 
    const loading = ref<boolean>(false);
    const loadingDin = ref<boolean>(false);
    var formData = ref({})
    interface IBranches {
      kmp_id,
      kmp_din,
      kmp_dob,
      kmp_gender,
      kmp_email_id,
      kmp_name,
      kmp_mobile_no,
      kmp_designation,      
      active: {
        label: string;
        color: string;
      }
    }

    const rules = ref({
      name: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",          
        },        
      ],
      email: [
        {
          required: true,
          message: "Email is required",
          trigger: "change",
        },        
      ],
      mobile: [
        {
          required: true,
          message: "Mobile is required",
          trigger: "change",
          min: 10,
          max: 10
        },
      ],
      // din: [
      //   {
      //     required: true,
      //     message: "DIN is required",
      //     trigger: "change",
      //     min: 8,
      //     max: 8
      //   },
      // ],      
      dob: [
        {
          required: true,
          message: "DOB is required",
          trigger: "change",
        },
      ],
      designation_select: [
        {
          required: true,
          message: "Designation is required",
          trigger: "change",
        },
      ],
      gender: [
        {
          required: true,
          message: "Gender is required",
          trigger: "change",
        },
      ]       
    });  

     const getDinData = async () => {
        
        loadingDin.value = true;
        
        const db_data = {"kmp_id" : 0, 
        "kmp_din" : formData.value['din'], 
        "page" : 1, 
        "records_per_page" : 10 }

        await store.dispatch(Actions.CUST_GET_DIN_BY_SEARCH, db_data).then(({ data }) => {

          if(data){

            formData.value['dob'] = data['kmp_dob']            
            formData.value['gender'] = data['kmp_gender_name']
            formData.value['email'] = data['kmp_email_id']
            formData.value['mobile'] = data['kmp_mobile_no']
            formData.value['designation_select'] = data['kmp_designation_name']
            formData.value['id'] = data['kmp_id']
            formData.value['name']= data['kmp_name']
            
            loadingDin.value = false;

          }else{

            formData.value['dob'] = ""
            formData.value['gender'] = ""
            formData.value['email'] = ""
            formData.value['mobile'] = ""
            formData.value['designation_select'] = ""
            formData.value['id'] = ""
            formData.value['name'] = ""

            Swal.fire({
              text: "Sorry, It's Invalid DIN!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            
            loadingDin.value = false;

          }
        
        })
        .catch(({ response }) => {
          
          Swal.fire({
              text: "Sorry, It's Invalid DIN!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            
            loadingDin.value = false;
        });
    }

     const setFormData = async (data) => {    
       console.log("DATA")
       console.log(data)
       data = data['kmp_id'];
        try {

        var values = { 
          "kmp_id" : data,
          "kmp_din":"0", 
          "page" : 1,
          "records_per_page" : 10
        }
         await store.dispatch(Actions.CUST_GET_DIN_BY_SEARCH, values).then(({ data }) => {   
          console.log('set form')
          console.log(data)    
          formData.value = {
            id : data.kmp_id,
            name: data.kmp_name,
            din: data.kmp_din,
            email : data.kmp_email_id,
            gender : data.kmp_gender_name,
            mobile : data.kmp_mobile_no,
            dob : data.kmp_dob,
            designation_select : data.kmp_designation_id,
            active : true,
            };
          console.log(formData.value)
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }

    const setKmpData = async (data) => {

      var user = JSON.parse(JwtService.getToken());
      var dat = new Date(data.dob)
      var dt = ("0" + dat.getDate()).slice(-2)
      var mo = ("0" + (dat.getMonth() + 1)).slice(-2)
      var yy = dat.getFullYear()
      var dtt = (yy+'-'+mo+'-'+dt);

      const db_data = {
        "kmp_id": data.id,
        "kmp_name": data.name,
        "kmp_din": data.din,
        "kmp_designation_id" : data.designation_select,
        "kmp_gender_id" : data.gender == "Male" ? 1 : 2,
        "kmp_email_id" : data.email,
        "kmp_mobile_no" : data.mobile,
        "kmp_dob" : dtt,
        "active": 1,
        "user_id": user.user_id
        
        }                                                                                                                                                                                                                                               

      await store.dispatch(Actions.CUST_GET_USER_KMP_UPDATE, db_data).then(({ data }) => {

        if (data){

            setTimeout(() => {
              loading.value = false;
              hideModal(editkmpModalRef.value);
              Swal.fire({
                text: "KMP has been successfully Updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                refreshData()
                router.push({name : "kmp-listing"});        
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }   

    var paginationData = ref({})
    
    paginationData.value = {      
          start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,
        mmaxPageg : 6,
    }

    const refreshData =() => {   
      //paginationData.value['activePage']  = 1;      
      getKmpCompList(props.id)
    }

    const changePageChange = (page) => {      
      //paginationData.value['activePage']  = page;
      getKmpCompList(props.id)
    }
    
    var tableData = ref<Array<IBranches>>([]);
    
    const getKmpCompList = async (data) => {
      
      loadingData.value = true;

      try {

        var values = { "company_id"  :  data}
        await store.dispatch(Actions.CUST_GET_KMP_COMP_LIST, values).then(({ data }) => {

        tableData.value = ([]);
        
        var resultsM = ref<Array<IBranches>>([])
        var active_label = ""
        var active_color = ""

        for (let j = 0; j < data.result_list.length; j++) {

          if (data.result_list[j]['active']){
              active_label = "Yes";
              active_color = "success";                  
          }else{
              active_label = "No";
              active_color = "danger";
          }

          resultsM.value = Array({

            kmp_id : data.result_list[j]['kmp_id'],
            kmp_din : data.result_list[j]['kmp_din'],
            kmp_dob : data.result_list[j]['kmp_dob'],
            kmp_gender : data.result_list[j]['kmp_gender_name'] == 'NA'  ? "-" : data.result_list[j]['kmp_gender_name'],
            kmp_email_id : data.result_list[j]['kmp_email_id'],
            kmp_name : data.result_list[j]['kmp_name'],
            kmp_mobile_no : data.result_list[j]['kmp_mobile_no'],
            kmp_designation : data.result_list[j]['kmp_designation_name'],
            active: {
              label: active_label,
              color: active_color
            }

          })

          tableData.value.splice(j, resultsM.value.length, ...resultsM.value);            
        }

        loadingData.value = false;

      })
      .catch(({ response }) => {
        
        tableData.value = [];
        loadingData.value = false; 
      });
        
      } catch (e) {
        console.log(e);
      }
    };

      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });

      // var tableData = ref<Array<WICompanies>>([]);
      const initCustomers = ref<Array<IBranches>>([]);                      

      onMounted( async () => {
        await getKmpCompList(props.id)
        await setFormData(props.data);
        // setCurrentPageBreadcrumbs("KMP's",["company"]);
        initCustomers.value.splice(0, tableData.value.length, ...tableData.value);                
      });  

      const deleteFewCustomers = () => {
        checkedBranch.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedBranch.value.length = 0;
      };

      const deleteCustomer = (id) => {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].kmp_id === id) {
            tableData.value.splice(i, 1);
          }
        }
      };

      const search = ref<string>("");
      const searchItems = () => {
        tableData.value.splice(0, tableData.value.length, ...initCustomers.value);        
        if (search.value !== "") {
          let results: Array<IBranches> = [];          
          for (let j = 0; j < tableData.value.length; j++) {
            if (searchingFunc(tableData.value[j], search.value)) {              
              results.push(tableData.value[j]);
            }
          }
          tableData.value.splice(0, tableData.value.length, ...results);
        }
      };

      const searchingFunc = (obj, value): boolean => {        
        for (let key in obj) {          
          if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {            
            if (obj[key].indexOf(value) != -1) {
              
              return true;
            }
          }
        }
        return false;
      };   
      
      const submit = async () => {
      
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {
        if (valid) {
          await setKmpData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    return {
      tableData,      
      deleteCustomer,
      designation_item,
      search,
      searchItems,
      checkedBranch,
      deleteFewCustomers,
      count,     
      editkmpModalRef, 
      getKmpCompList,       
      paginationData,
      changePageChange,
      refreshData,      
      loadingData,
      formData,
      formRef,
      loading,
      loadingDin,
      setFormData,
      submit,
      rules,
      ids
    };  

  }  

});


