
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {business_natures, group_types, industry_types_pc, business_types} from "@/core/data/genericData";
import axios from "axios";
import {mask} from 'vue-the-mask'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { sez } from "@/core/data/genericData";
export default defineComponent({
  name: "edit-company-modal",  
  directives: {mask},
  props: {
    data: {
        type: Object
    }
  },
  components: {    
    },    
  methods :{
     onClearPan() {
      if (this.formData['pan_search'] == "" || this.formData['pan_search'] !== "10") {
        this.formData['company_pan'] = "";
      }
    },
  },

  setup(props) {    

    // this.$forceUpdate();
    const store = useStore();
    const router = useRouter();    
    var formData = ref({});
    const loadingPan = ref<boolean>(false);
    const loadingPin = ref<boolean>(false);
    const business_type = ref([]);
    const setBusinessTypedata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            business_type.value = body.business_types;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const getPanData = async () => {
      loadingPin.value = true;

      const db_data = { pan: formData.value['pan_search'] };

      await store
        .dispatch(Actions.CUST_GET_USER_PROFILE_PAN_SEARCH, db_data)
        .then(({ data }) => {
          console.log(data);

          if (data.holder_type == 'company') {
            formData.value['company_pan'] = data["pan"];
            // formData1.value['city'] = data["holder_type"];
            // formData1.value['state'] = data["holder_name"];
            

            loadingPin.value = false;
          } else {
            formData.value['company_pan'] = "";
            

            Swal.fire({
              text: "Sorry, It's Invalid PAN!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            formData.value['pan_search'] = "",
            loadingPin.value = false;
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Sorry, It's Invalid PAN!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });

          loadingPin.value = false;
        });
    };

    const roc_list_data = ref([]);
    const setROCdata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            roc_list_data.value = body.roc_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const industry_types_data = ref([]);
    const setIndustryTypedata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            industry_types_data.value = body.industry_types;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const nob_data = ref([]);
    const setNobdata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            nob_data.value = body.business_natures;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };
    const nob_value = ref()
    const industry_value = ref()
    const business_value = ref()
    const pan_data = ref()

    const setFormData = async (data) => {    

        console.log(data.ids);
        var j = 0;

        try {

          var values = { "company_id"  :  data.ids, "page"  : 1, "records_per_page" : 1 }
          
          await store.dispatch(Actions.CUST_GET_USER_PROFILE_LIST, values).then(({ data }) => {
            console.log('DATAADDADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA')
            console.log(data)
            var date_data 
            if(data.company_doi == '0000-00-00'){
               formData.value['incorporationateDate'] = ''
               console.log("UNDEFINED")
              console.log(typeof(formData.value['incorporationateDate']))
            }
            if(data.company_doi !== '0000-00-00'){
              date_data = data.company_doi
            }

           if(data.industry_type_select == 0){
              industry_value.value = 'NONE'
            }
            if(data.nob_select == 0){
              nob_value.value = 'NA'
            }
            if(data.business_type_select == 0){
              business_value.value = 'NA'
            }
            pan_data.value = data.pan_verified_yn
            formData.value = {
              id: data.company_id,
              name: data. company_name,
              company_pan : data.company_pan,
              legal_name : data.company_legal_name,
              nob_select : data.company_nob_id|| nob_value.value,
              industry_type_select : data.company_industry_type_id || industry_value.value,
              business_type_select : data.company_business_type_id || business_value.value,
              incorporationateDate : (date_data == '' ? "0000-00-00" :  date_data),
              cinn  :  data.company_business_type_id != 6 ? data.company_cin_llpin : "",
              llpinn  :  data.company_business_type_id == 6 ? data.company_cin_llpin : "",
              registrationNo : Number(data.company_registration_number),
              roc_select : data.company_roc_id,
              website : data.company_website,
              active : data.active ? 'Yes' : 'No',
              verified : data.company_verified_yn ? 'Yes' : 'No',
              pan_verified : pan_data.value,
              sez: data.sez_yn,
            };
            console.log(formData.value)
            console.log(pan_data.value)
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }

    onMounted( async () => {        
        await setFormData(props.data);
        await setNobdata();
        await setIndustryTypedata();
        await setBusinessTypedata();
        await setROCdata();
      });  

    // const nob_data = business_natures;
    // const group_type_data = group_types;
    // const business_types_data = business_types;
    // const roc_list_data = roc_list;
    const sez_data = sez;
    const group_type_data = ref([]);
    const formRef = ref<null | HTMLFormElement>(null);
    const editUserProfileCompanyModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);    


    const rules = ref({
      basic_name: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",
        },
      ],
      name_of_person: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",
        },
      ],
      mobile_select: [
        {
          required: true,
          message: "Mobile number is required",
          trigger: "change",
        },
        {
          required: true,
          message: "Please enter 10 digit mobile no.",
          trigger: "change",
          min: 10,
          max: 10
        },
      ],
    });

    const setCompanyData = async (data) => {          
      console.log('DATATAATAT')
      console.log(data.pan_verified)
      var defaultdate = "";
      var dtt = "";
      var cinLlpin = "";
      const panverify = ref();
      const pansea = ref();
      var user = JSON.parse(JwtService.getToken());

      try {
          if(data.incorporationateDate.includes('-')){
            dtt = data.incorporationateDate
          }    
          
        }
        catch{
          
          console.log('catch');
          //console.log(data.incorporationateDate)
          
        }

        if(dtt){
          console.log('aa');
        }
        else if(data.incorporationateDate == undefined){
          //console.log("ZZZZZZZZZZZZZZZZZZZZZZZ")
          dtt = ''
        }
        
        else{

          var dt = ("0" + data.incorporationateDate.getDate()).slice(-2)
          var mo = ("0" + (data.incorporationateDate.getMonth() + 1)).slice(-2)
          var yy = data.incorporationateDate.getFullYear()
          dtt = (yy+'-'+mo+'-'+dt);
          console.log("hellooooooooooo")
        }

        if(data.incorporationateDate == 'undefined'){
        console.log("DATAE UNDEFINED")
      }

      cinLlpin = data.llpinn == "" ? data.cinn : data.llpinn
      // if(data.pan_verified == 0){
      //   panverify.value = 0
      // }
      // console.log(formData.value['pan_search'])
       
      // if(formData.value['pan_search'] === undefined){
      //   panverify.value = 0
      //   console.log("PAN SERACH")
       
      //   console.log(panverify.value)
      // }
      // else{
      //   panverify.value = 1
      // }

     
      
      if(formData.value['pan_search'] === undefined){
        if(data.pan_verified == 1){
          panverify.value = 1
        }
        else{
          panverify.value = 0
        }
      }
      else{
        panverify.value = 1
      }
      
      const db_data = {
        "company_id": data.id,
        "company_name": data.name,
        "company_name_alternative": data.alternativename || "",
       // "company_nob": data.nob_select || "",
        // "company_group_type": data.group_type_select,
        "company_industry_type_id": data.industry_type_select || 0,
        "company_legal_name": data.legal_name || "",
        "company_business_type_id": data.business_type_select || 0,
        "company_roc_id": data.roc_select === "" ? 0 : data.roc_select,
        "company_doi": dtt ,
        "company_nob": data.nob_select || 0,
        "company_cin_llpin": cinLlpin !== "" ? cinLlpin : 0,
        "company_registration_number": Number(data.registrationNo === "" ? 0 : data.registrationNo),
        "company_pan": data.company_pan,
        "sez_yn" : Number(data.sez || ""),
        "company_website": data.website,
       // "company_verified_yes_no": data.verified == 'Yes' ? true : false,
        "active": data.active  == 'Yes' ? true : false,
        "company_trade_type_id":1,
        "pan_verified_yn": panverify.value,
        "user_id": user.user_id,        
      }

      console.log('DBBBBBBBBBBBBBB')
      console.log(db_data)
       
        await store.dispatch(Actions.CUST_EDIT_COMPANY, db_data).then(({ data }) => {

        if (data){
        
            setTimeout(() => {
              loading.value = false;
              hideModal(editUserProfileCompanyModalRef.value);
              Swal.fire({
                text: "Company has been successfully updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                router.go(0);            
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }

    const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setCompanyData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });  
    }
    
   

    return {
      nob_data,
      setNobdata,
      industry_types_data,
      setIndustryTypedata,
      business_type,
      setBusinessTypedata,
      roc_list_data,
      setROCdata,
      getPanData,
      loadingPin,
      loadingPan,
      formData,
      rules,
      submit,
      formRef,
      loading,
      sez_data,
      editUserProfileCompanyModalRef,
    };
  },
});
