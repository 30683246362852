

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddUserProfileBranch from "@/components/modals/forms/AddUserProfileBranch.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, {business_types} from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { mask } from "vue-the-mask";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import JwtService from "@/core/services/JwtService";
import { hideModal } from "@/core/helpers/dom";


export default defineComponent({

  name: "branch-listing",
  directives: { mask },
  props: {    
    id: { type: Object },
    data: {
        type: Object
    },
  },
  components: {
    ExportCustomerModal,
    AddUserProfileBranch,     
  },
  methods: {
    onChange() {
      if (this.formData['pincode'] == "") {
        this.formData['city'] = "";
        this.formData['state'] = "";
        this.formData['country'] = "";
      }
    },
    onClearGst() {
      if (
        this.formData['gstin_search'] == "" ||
        this.formData['gstin_search'] !== "15"
      ) {
        this.formData['pincode'] = "";
        this.formData['city'] = "";
        this.formData['state'] = "";
        this.formData['country'] = "";
        this.formData['jurisdiction'] = "";
        this.formData['legal_name'] = "";
        this.formData['address_line_1'] = "";
        this.formData['gst_type'] = "";
        this.formData['gstin'] = "";
        this.formData['sez'] = "";
        this.formData['state_tin'] = "";
      }
    },
  }, 
  
  setup(props) {

    var ids = ref(props['id']);
    const count = ref(0)
    const store = useStore();
    const checkedBranch = ref([]);    
    const loadingData = ref<boolean>(true);
    const loading = ref<boolean>(false);
    const loadingPin = ref<boolean>(false);
    const router = useRouter();
    var formData = ref({})
    const city_value = ref()
    const pin_value = ref()
    const state_value = ref()
    const country_value = ref()
    const formRef = ref<null | HTMLFormElement>(null);
    const editBranchModalRef = ref<null | HTMLElement>(null);
    interface IBranches {
      company_id,
      company_branch_id,
      branch_title_type_name,
      gstin,
      gstin_verified_yn,
      branch_verified_yn,
      city_name,
      state_name,
      branch_name,
      status: {
        label: string;
        color: string;
      },
      active: {
        label: string;
        color: string;
      }
    }

    const branch_title = ref([]);
    const setBranchTitledata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            branch_title.value = body.branch_title_types;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

   const pin = ref([]);
    const getPincodeData = async (search_data) => {
      loadingPin.value = true;

      const db_data = { search_term: search_data };

      if (db_data.search_term) {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_PINCODE_SEARCH, db_data)
          .then(({ data }) => {
            pin.value = data;

            for (let i = 0; i < data.length; i++) {
               formData.value['city'] = data[i].city_name
              formData.value['state'] = data[i].state_name
              formData.value['country'] = data[i].country_name
              formData.value['city_id'] = data[i].city_id
              formData.value['state_id'] = data[i].state_id
              formData.value['pincode_id'] = data[i].pincode_id
              formData.value['country_id'] = data[i].country_id


              loadingPin.value = false;
              console.log();
            }
          })

          .catch(({ response }) => {
            Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          });
      }
      // else{

      //     formData.value.city = ""
      //     formData.value.state = ""
      //     formData.value.country = ""
      //     formData.value.city_id = ""
      //     formData.value.state_id = ""
      //     formData.value.pincode_id = ""
      //     formData.value.country_id = ""

      //     Swal.fire({
      //       text: "Sorry, It's Invalid Pincode!",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });

      //     loadingPin.value = false;

      //   }
    };

    const ClickPincodeData = async (pin_details) => {
      formData.value['city'] = pin_details.city_name
      formData.value['state'] = pin_details.state_name
      formData.value['country'] = pin_details.country_name
      
      city_value.value = pin_details.city_id
      pin_value.value =  pin_details.pincode_id
      state_value.value = pin_details.state_id
      country_value.value = pin_details.country_id
    };

    const title = ref([]);
    const setTitledata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_ADDRESS_TITLE_LIST_DATA)
          .then(({ data }) => {
            title.value = data;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const onMobileEnter = () => {
      debugger;
      if (
        /^[789]\d{9}$/.test(formData.value["business_mobile"]) &&
        !formData.value["mobile_list"].includes(
          formData.value["business_mobile"]
        )
      ) {
        formData.value["mobile_list"].push(formData.value["business_mobile"]);
        formData.value["business_mobile"] = "";
      }
    };

    const onLandLineEnter = () => {
      debugger;
      if (
        /\d{5}([- ]*)\d{6}/.test(formData.value["business_landline"]) &&
        !formData.value["landline_list"].includes(
          formData.value["business_landline"]
        )
      ) {
        formData.value["landline_list"].push(
          formData.value["business_landline"]
        );
        formData.value["business_landline"] = "";
      }
    };

    const onEmailEnter = () => {
      debugger;
      if (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          formData.value["business_emails"]
        ) &&
        !formData.value["email_list"].includes(
          formData.value["business_emails"]
        )
      ) {
        formData.value["email_list"].push(formData.value["business_emails"]);
        formData.value["business_emails"] = "";
      }
    };

    const getGstData = async () => {
      loadingPin.value = true;

      const db_data = { gstin: formData.value['gstin_search'] };

      await store
        .dispatch(Actions.CUST_GET_USER_PROFILE_GSTIN_SEARCH, db_data)
        .then(({ data }) => {
          console.log(data);

          if (data) {
            formData.value['city'] = data["city_name"];
            formData.value['state'] = data["state_name"];
            formData.value['country'] = "India";
            formData.value['state_tin'] = data["tin"];
            formData.value['city_id'] = data["city_id"];
            formData.value['state_id'] = data["state_id"];
            formData.value['pincode_id'] = data["pincode_id"];
            formData.value['pincode'] = data["pincode"];
            formData.value['country_id'] = data["country_id"];
            formData.value['business_type_select'] = data["business_type_id"];
            formData.value['basic_name'] = data["legal_business_name"];
            formData.value['legal_name'] = data["legal_business_name"];
            formData.value['jurisdiction'] = data["state_jurisdiction"];
            formData.value['address_line_1'] = data["address"];
            formData.value['gst_type'] = data["gst_type"];
            formData.value['gstin'] = data["gstin"];
            formData.value['state_tin'] = data["state_tin"];

            loadingPin.value = false;
          } else {
            formData.value['city'] = "";
            formData.value['state'] = "";
            formData.value['country'] = "";
            formData.value['state_tin'] = "";
            formData.value['city_id'] = "";
            formData.value['state_id'] = "";
            formData.value['pincode_id'] = "";
            formData.value['country_id'] = "";

            Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Sorry, It's Invalid Pincode!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });

          loadingPin.value = false;
        });
    };

    const setFormData = async (data) => {    
      data = data['company_branch_id'];
        try {

       var values = { 
            "company_id"  : props.id, 
            "company_branch_id" : data,
            "page"  : 1, 
            "records_per_page" : 1 
            }
        
        await store.dispatch(Actions.CUST_GET_BRANCH_LIST, values).then(({ data }) => {    
          city_value.value = data.city_id
          pin_value.value = data.pincode_id
          state_value.value = data.state_id
          country_value.value = data.country_id
          
          formData.value = {
            "id": data.company_branch_id,
            name: data.branch_name,
            branch_title_select: data.branch_title_type_id,
            // gstin_search: "",
            landline_list: data.business_landline_json,
            email_list: data.business_email_json,
            mobile_list: data.business_mobile_json,
            address_line_1: data.address_line_1,
            address_line_2: data.address_line_2,
            pincode: data.pincode,
            pincode_id: data.pincode_id,
            city: data.city_name,
            state: data.state_name,
            country: data.country_name,
            gstin: data.gstin,
            state_tin: data.state_tin,
            gst_type: data.gst_type,
            jurisdiction: data.jurisdiction,
            
            };
          
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }

    const rules = ref({
      name: [
        {
          required: true,
          message: "Branch Name is required",
          trigger: "change",
        },
      ],

    });

     const setBranchData = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      
      const db_data = {
        company_branch_id: data.id,
        branch_name: data.name,
        company_id: Number(props.id),
        branch_title_type_id: data.branch_title_select || 0,
        capacity_in_mt : 0,
        capacity_duration : "",
        business_email_json: data.email_list,
        business_mobile_json: data.mobile_list,
        business_landline_json: data.landline_list,
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        pincode_id: pin_value.value || data.pincode_id ,
        gstin: data.gstin || "",
        state_tin: data.state_tin || "",
        gst_type: data.gst_type || "",
        jurisdiction: data.jurisdiction || "",
        branch_verified_yn :0,
        gstin_verified_yn: data.gstin_search !== "" ? 1 || data.gstin_search : 0,
        verified_yes_no: false,
        active: 1,
        user_id: user.user_id,
      };

      await store
        .dispatch(Actions.CUST_ADD_BRANCH, db_data)
        .then(({ data }) => {
          if (data.is_success) {
            setTimeout(() => {
              loading.value = false;
              hideModal(editBranchModalRef.value);
              Swal.fire({
                text: "Branch has been successfully Updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                refreshData()
                router.push({name : "branch-listing"});
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          return false;
        });
    }

    var paginationData = ref({})
    
    paginationData.value = {      
          start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

    const refreshData =() => {
      paginationData.value['activePage']  = 1;      
      getBranchList(ids)
    }

    const changePageChange = (page) => {
      paginationData.value['activePage']  = page;
      getBranchList(ids)
    }
    
    var tableData = ref<Array<IBranches>>([]);
    
    const getBranchList = async (data) => {

      loadingData.value = true;
      
      var com_id = 0
      if(data._value){
        com_id = data._value
      }else{
        com_id = data 
      }
      
      try {
        
        var values = { "company_id"  : com_id, 
         "company_branch_id" : 0,
        "page"  : parseInt(paginationData.value['activePage']), 
        "records_per_page" : parseInt(paginationData.value['perPage']) }
        
        await store.dispatch(Actions.CUST_GET_BRANCH_LIST, values).then(({ data }) => {

          tableData.value = ([]);

          //pagination
          paginationData.value['total'] = data.total_records;        
          paginationData.value['start'] = data.records_from
          paginationData.value['end'] = data.records_upto
          paginationData.value['activePage'] = data.page;
          paginationData.value['totPage']  = data.total_pages
          paginationData.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }

          var resultsM = ref<Array<IBranches>>([])
          var status_label = ""
          var status_color = ""
          var active_label = ""
          var active_color = ""

          for (let j = 0; j < data.result_list.length; j++) {          

            if (data.result_list[j]['active']){
                active_label = "Yes";
                active_color = "success";                  
            }else{
                active_label = "No";
                active_color = "danger";
            }
            
            resultsM.value = Array({
              branch_verified_yn : data.result_list[j]['branch_verified_yn'],
              company_id : data.result_list[j]['company_id'],
              company_branch_id : data.result_list[j]['company_branch_id'],
              gstin : data.result_list[j]['gstin'],
              gstin_verified_yn : data.result_list[j]['gstin_verified_yn'],
              city_name : data.result_list[j]['city_name'],
              state_name : data.result_list[j]['state_name'],
              branch_name : data.result_list[j]['branch_name'],
              branch_title_type_name : data.result_list[j]['branch_title_type_name'],
              status: {
                label: status_label,
                color: status_color
              },
              active: {
                label: active_label,
                color: active_color
              }
            })

            tableData.value.splice(j, resultsM.value.length, ...resultsM.value);            
          }

          loadingData.value = false;

        })
        .catch(({ response }) => {

          tableData.value = [];
          loadingData.value = false;

        });
        
      } catch (e) {
        console.log(e);
      }
    };

      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });

      // var tableData = ref<Array<WICompanies>>([]);
      const initCustomers = ref<Array<IBranches>>([]);                      

      onMounted( async () => {
        await getBranchList(props.id)
        await setBranchTitledata();
        await setFormData(props.data);
        
        // setCurrentPageBreadcrumbs("Branches",[]);
        initCustomers.value.splice(0, tableData.value.length, ...tableData.value);              
      });  

      const deleteFewCustomers = () => {
        checkedBranch.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedBranch.value.length = 0;
      };

      const deleteCustomer = (id) => {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].company_branch_id === id) {
            tableData.value.splice(i, 1);
          }
        }
      };

      const search = ref<string>("");
      const searchItems = () => {
        tableData.value.splice(0, tableData.value.length, ...initCustomers.value);        
        if (search.value !== "") {
          let results: Array<IBranches> = [];          
          for (let j = 0; j < tableData.value.length; j++) {
            if (searchingFunc(tableData.value[j], search.value)) {              
              results.push(tableData.value[j]);
            }
          }
          tableData.value.splice(0, tableData.value.length, ...results);
        }
      };

      const searchingFunc = (obj, value): boolean => {        
        for (let key in obj) {          
          if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {            
            if (obj[key].indexOf(value) != -1) {
              
              return true;
            }
          }
        }
        return false;
      };    
      
      const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setBranchData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };
       
    return {
      formData,
      tableData,      
      deleteCustomer,
      search,
      searchItems,
      checkedBranch,
      deleteFewCustomers,
      count,      
      getBranchList,       
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      setFormData,
      onMobileEnter,
      onEmailEnter,
      onLandLineEnter,
      pin,
      getPincodeData,
      ClickPincodeData,
      getGstData,
       loading,
      loadingPin,
      editBranchModalRef,
      submit,
      formRef,
      branch_title,
      setBranchTitledata,
      ids,
      rules,
    };  

  }  

});




