
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import  { user_gst_type} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { gst_type, gst_return_frequency } from "@/core/data/genericData";

export default defineComponent({
  name: "add-branch-modal",
  directives: { mask },
  props: {
    id: { required: true },
  },
  components: {},
  methods: {
    onChange() {
      if (this.formData1.pincode == "") {
        this.formData1.city = "";
        this.formData1.state = "";
        this.formData1.country = "";
      }
    },
     onClearPan() {
      if (this.formData1.pan_search == "" || this.formData1.pan_search !== "10") {
        this.formData1.individual_pan = "";
      }
    },
  }, 
  setup(props) {
    const user_gst_type_data = user_gst_type;
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const addContactModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingPin = ref<boolean>(false);
    const router = useRouter();
    const type_list_data = gst_type;
    const freq_list_data = gst_return_frequency;

    const formData = ref({
      
    });
    
    const onMobileEnter = () => {
      debugger;
      if (
        /^[789]\d{9}$/.test(formData.value["business_mobile"]) &&
        !formData.value["mobile_list"].includes(
          formData.value["business_mobile"]
        )
      ) {
        formData.value["mobile_list"].push(formData.value["business_mobile"]);
        formData.value["business_mobile"] = "";
      }
    };

    const onLandLineEnter = () => {
      debugger;
      if (
        /\d{5}([- ]*)\d{6}/.test(formData.value["business_landline"]) &&
        !formData.value["landline_list"].includes(
          formData.value["business_landline"]
        )
      ) {
        formData.value["landline_list"].push(
          formData.value["business_landline"]
        );
        formData.value["business_landline"] = "";
      }
    };

    const onEmailEnter = () => {
      debugger;
      if (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          formData.value["business_emails"]
        ) &&
        !formData.value["email_list"].includes(
          formData.value["business_emails"]
        )
      ) {
        formData.value["email_list"].push(formData.value["business_emails"]);
        formData.value["business_emails"] = "";
      }
    };

    const designation = ref([]);
    const setDesignationdata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            designation.value = body.contact_designation_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const department = ref([]);
    const setDepartmentdata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            department.value = body.department_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

     const getPanData = async () => {
      loadingPin.value = true;

      const db_data = { pan: formData1.value['pan_search'] };

      await store
        .dispatch(Actions.CUST_GET_USER_PROFILE_PAN_SEARCH, db_data)
        .then(({ data }) => {
          console.log(data);

          if (data.holder_type == 'individual') {
            formData1.value['individual_pan'] = data["pan"];
            // formData1.value['city'] = data["holder_type"];
            // formData1.value['state'] = data["holder_name"];
            

            loadingPin.value = false;
          } else {
            formData.value['individual_pan'] = "";
            

            Swal.fire({
              text: "Sorry, It's Invalid PAN!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            formData.value['pan_search'] = "",
            loadingPin.value = false;
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Sorry, It's Invalid PAN!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });

          loadingPin.value = false;
        });
    };

   
    const formData1 = ref({
      // individual_pincode : "",
      // individual_city : "",
      // individual_state : "",
      // individual_country : "",
      pan_search: "",
      individual_spouse_dob: "",
      individual_anniversary_dob: "",
      individual_dob: "",
      individual_mobile_select: "",
      individual_email_select: "",
      individual_landline_select: "",
      individual_department_select: "",
      individual_address_line_1: "",
      individual_address_line_2: "",
      individual_designation_select: "",
      individual_name_of_person: "",
      individual_pan: "",
      pincode: "",
      pincode_id: "",
      city: "",
      state: "",
      country: "",
      city_id: "",
      state_id: "",
      country_id: "",
      branch_select: "",
    });

    onMounted(async () => {
      //const db_data = {}
      await setTitledata();
      await setBranchTitledata();
      await setDesignationdata();
      await setDepartmentdata();
      await setBranchdata();
    });

    const rules = ref({
      individual_name_of_person: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",
        },
      ],
      individual_designation_select: [
        {
          required: true,
          message: "Designation is required",
          trigger: "change",
        },
      ]

    });

    const branch_name = ref([]);
    const setBranchdata = async () => {
      const values = {
       "company_branch_id" : 0,
       "company_id":props.id,
       "page" : 1,
       "records_per_page" : 100
      };
      loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_BRANCH_LIST, values)
          .then(({ data }) => {
            branch_name.value = data.result_list;
            loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const branch_title = ref([]);
    const setBranchTitledata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            branch_title.value = body.branch_title_types;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

   const pin = ref([]);
    const getPincodeData = async (search_data) => {
      loadingPin.value = true;

      const db_data = { search_term: search_data };

      if (db_data.search_term) {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_PINCODE_SEARCH, db_data)
          .then(({ data }) => {
            pin.value = data;

            for (let i = 0; i < data.length; i++) {
              formData1.value.city = data[i].city_name;
              formData1.value.state = data[i].state_name;
              formData1.value.country = data[i].country_name;
              formData1.value.city_id = data[i].city_id;
              formData1.value.state_id = data[i].state_id;
              formData1.value.pincode_id = data[i].pincode_id;
              formData1.value.country_id = data[i].country_id;

              loadingPin.value = false;
              console.log();
            }
          })

          .catch(({ response }) => {
            Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          });
      }
      // else{

      //     formData.value.city = ""
      //     formData.value.state = ""
      //     formData.value.country = ""
      //     formData.value.city_id = ""
      //     formData.value.state_id = ""
      //     formData.value.pincode_id = ""
      //     formData.value.country_id = ""

      //     Swal.fire({
      //       text: "Sorry, It's Invalid Pincode!",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });

      //     loadingPin.value = false;

      //   }
    };

    const ClickPincodeData = async (pin_details) => {
      formData1.value.city = pin_details.city_name;
      formData1.value.state = pin_details.state_name;
      formData1.value.country = pin_details.country_name;
      formData1.value.city_id = pin_details.city_id;
      formData1.value.state_id = pin_details.state_id;
      formData1.value.pincode_id = pin_details.pincode_id;
      formData1.value.country_id = pin_details.country_id;
    };

    const title = ref([]);
    const setTitledata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_ADDRESS_TITLE_LIST_DATA)
          .then(({ data }) => {
            title.value = data;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    var indt, inmo, inyy, dt_ani, mo_ani, yy_ani, dt_spouse, mo_spouse, yy_spouse, ind_dtt, ind_anni_dtt, ind_spouse_dtt,defaultdate

    async function setContactData(data) {
      var user = JSON.parse(JwtService.getToken());

      if(data.individual_dob !== ''){
        indt = ("0" + data.individual_dob.getDate()).slice(-2)
           inmo = ("0" + (data.individual_dob.getMonth() + 1)).slice(-2)
           inyy = data.individual_dob.getFullYear()
          ind_dtt = (inyy+'-'+inmo+'-'+indt);
      }
      else{
        defaultdate = '0000-00-00'
      } 

      if(data.individual_anniversary_dob !== ''){
         dt_ani = ("0" + data.individual_anniversary_dob.getDate()).slice(-2)
           mo_ani = ("0" + (data.individual_anniversary_dob.getMonth() + 1)).slice(-2)
          yy_ani = data.individual_anniversary_dob.getFullYear()
         ind_anni_dtt = (yy_ani+'-'+mo_ani+'-'+dt_ani);
      }
      else{
        defaultdate = '0000-00-00'
      }

      if(data.individual_spouse_dob !== ''){
        dt_spouse = ("0" + data.individual_spouse_dob.getDate()).slice(-2)
           mo_spouse = ("0" + (data.individual_spouse_dob.getMonth() + 1)).slice(-2)
           yy_spouse = data.individual_spouse_dob.getFullYear()
         ind_spouse_dtt = (yy_spouse+'-'+mo_spouse+'-'+dt_spouse);
      }
      else{
        defaultdate = '0000-00-00'
      }

      const db_data = {
        contact_name: data.individual_name_of_person,
        company_branch_id: data.branch_select,
        company_id: Number(props.id),
        designation_id: data.individual_designation_select,
        department_id: data.individual_department_select,
        email: data.individual_email_select,
        mobile_no: data.individual_mobile_select,
        landline_no: data.individual_landline_select,
        contact_dob: ind_dtt || defaultdate,
        contact_anniversary_date: ind_anni_dtt || defaultdate,
        contact_spouse_dob: ind_spouse_dtt || defaultdate,
        contact_pan: data.individual_pan,
        pan_verified_yn: data.pan_search !== "" ? 1 || data.pan_search : 0,
        address_line_1: data.individual_address_line_1,
        address_line_2: data.individual_address_line_2,
        pincode_id: Number(data.pincode_id) || 0,
        user_id: user.user_id,
      };

      await store
        .dispatch(Actions.CUST_GET_USER_CONTACT_ADD, db_data)
        .then(({ data, message }) => {
          if (data.is_success) {
            setTimeout(() => {
              loading.value = false;
              //
              Swal.fire({
                text: message,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addContactModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          return false;
        });
    }

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setContactData(formData1.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    return {
      formData1,
      rules,
      submit,
      pin,
      getPincodeData,
      ClickPincodeData,
      formRef,
      loading,
      loadingPin,
      branch_title,
      designation,
      department,
      setBranchTitledata,
      setDesignationdata,
      setDepartmentdata,
      user_gst_type_data,
      addContactModalRef,
      freq_list_data,
      type_list_data,
      setTitledata,
      onMobileEnter,
      onEmailEnter,
      onLandLineEnter,
      title,
      branch_name,
      setBranchdata,
      getPanData,
    };
  },
});
